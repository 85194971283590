class UndoManager {
    constructor() {
        this.changes = [];
    }

    addChange(cellAddress, originalValue) {
        this.changes.push({ cellAddress, originalValue });
    }

    getRevertData() {
        return this.changes;
    }

    async undoChanges(graphService) {
        const revertRequests = this.changes.map(change => 
            graphService.updateCell('Accounts', change.cellAddress, change.originalValue, true)
        );
        await Promise.all(revertRequests);
        this.changes = [];
    }
}

export default UndoManager;
