// DetailView.js
import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import GraphService from '../utils/GraphService';
import GroupManager from './GroupManager';
import './DetailView.css';
import UndoManager from './UndoManager';

class DetailView extends Component {
    undoManager = new UndoManager();

    constructor(props) {
        super(props);

        const cachedVisibleGroups = JSON.parse(localStorage.getItem('visibleGroups')) || [];
        const columnHeaders = props.columnHeaders || [];

        this.state = {
            editData: props.rowData?.values?.slice(0, columnHeaders.length) || [],
            originalData: props.rowData?.values ? [...props.rowData.values.slice(0, columnHeaders.length)] : [],
            initialData: props.rowData?.values ? [...props.rowData.values.slice(0, columnHeaders.length)] : [],
            isSaving: false,
            changesMade: [],
            isEnlargedViewOpen: false,
            enlargedCellIndex: null,
            enlargedCellValue: '',
            dialogVisible: false,
            hasChanges: false,
            visibleGroups: new Set(cachedVisibleGroups),
            saveSuccessDialogVisible: false,
            forceUpdate: false,
            cellChanges: {},
            revertData: [],
            rowHeight: 2, // Default row height is 2
        };

        this.graphService = new GraphService();
        this.groupManager = new GroupManager(columnHeaders);
        this.columnHeaders = columnHeaders;
    }

    toggleForceUpdate = () => {
        this.setState(prevState => ({
            forceUpdate: !prevState.forceUpdate
        }));
    };

    getCellAddress = (index) => {
        const rowIndex = this.props.rowData.rowIndex + 2;
        const columnLabel = this.getColumnLabel(index + 1);
        return `${columnLabel}${rowIndex}`;
    };

    getColumnLabel = (columnNumber) => {
        let temp,
            letter = '',
            columnLabel = '';
        while (columnNumber > 0) {
            temp = (columnNumber - 1) % 26;
            letter = String.fromCharCode(temp + 65);
            columnLabel = letter + columnLabel;
            columnNumber = (columnNumber - temp - 1) / 26;
        }
        return columnLabel;
    };

    componentDidUpdate(prevProps) {
        if (prevProps.rowData !== this.props.rowData) {
            const newValues = this.props.rowData?.values?.slice(0, this.columnHeaders.length) || [];
            this.setState({
                editData: newValues,
                originalData: newValues ? [...newValues] : [],
                initialData: newValues ? [...newValues] : [],
                hasChanges: false,
                rowHeight: 2, // Reset row height to default
            });
        }
    }

    handleCellChange = (index, value) => {
        this.setState(prevState => {
            const newEditData = [...prevState.editData];
            newEditData[index] = value;
            const newCellChanges = { ...prevState.cellChanges, [index]: true };

            // Get the text area element and adjust its height based on the content
            const textArea = document.getElementById(`textArea-${index}`);
            if (textArea) {
                textArea.style.height = 'auto';
                textArea.style.height = textArea.scrollHeight + 'px';
            }

            return {
                editData: newEditData,
                cellChanges: newCellChanges,
                hasChanges: true,
            };
        });
    };

    componentDidMount() {
        console.log("DetailView props.rowData:", this.props.rowData);
        console.log("DetailView state:", this.state);
    }

    handleEnlargedCellChange = (e) => {
        this.setState({ enlargedCellValue: e.target.value });
    };

    openEnlargedView = (index) => {
        this.setState({
            isEnlargedViewOpen: true,
            enlargedCellIndex: index,
            enlargedCellValue: this.state.editData[index] || '',
        });
    };

    closeEnlargedView = () => {
        if (this.state.enlargedCellValue !== this.state.editData[this.state.enlargedCellIndex]) {
            this.handleCellChange(this.state.enlargedCellIndex, this.state.enlargedCellValue);
        }
        this.setState({ isEnlargedViewOpen: false });
    };

    saveCurrentCell = async () => {
        const { enlargedCellIndex, enlargedCellValue, editData, originalData } = this.state;
        if (enlargedCellIndex !== null && enlargedCellValue !== editData[enlargedCellIndex]) {
            const cellAddress = this.getCellAddress(enlargedCellIndex);
            const originalValue = originalData[enlargedCellIndex];
            try {
                await this.graphService.updateCell('Accounts', cellAddress, enlargedCellValue);
                const newEditData = [...editData];
                newEditData[enlargedCellIndex] = enlargedCellValue;

                this.undoManager.addChange(cellAddress, originalValue);

                this.setState(prevState => ({
                    editData: newEditData,
                    hasChanges: true,
                    saveSuccessDialogVisible: true,
                    changesMade: [...prevState.changesMade, `${this.columnHeaders[enlargedCellIndex]}: ${originalValue || '*null*'} changed to ${enlargedCellValue || '*null*'}`],
                    revertData: [...prevState.revertData, {
                        cellAddress,
                        originalValue,
                        newValue: enlargedCellValue,
                    }]
                }));
            } catch (error) {
                console.error("Error updating cell:", error);
            }
        }
        this.closeEnlargedView();
    };

    renderDialogHeader = () => {
        const cellName = this.state.enlargedCellIndex !== null ? this.columnHeaders[this.state.enlargedCellIndex] : '';

        return (
            <div className="p-dialog-header-custom">
                <span>{cellName}</span>
            </div>
        );
    };

    toggleGroupVisibility = (groupName) => {
        this.setState(prevState => {
            const newVisibleGroups = new Set(prevState.visibleGroups);

            if (groupName === 'all') {
                newVisibleGroups.clear();
                newVisibleGroups.add('all');
            } else {
                if (newVisibleGroups.has(groupName)) {
                    newVisibleGroups.delete(groupName);
                } else {
                    newVisibleGroups.add(groupName);
                }

                if (newVisibleGroups.size === 0) {
                    newVisibleGroups.add('all');
                } else {
                    newVisibleGroups.delete('all');
                }
            }

            localStorage.setItem('visibleGroups', JSON.stringify([...newVisibleGroups]));

            return { visibleGroups: newVisibleGroups };
        });
    };

    handleUndo = async () => {
        const { initialData } = this.state;
        let errorsEncountered = false;

        try {
            await this.undoManager.undoChanges(this.graphService);
        } catch (error) {
            console.error("Error during undo operation:", error);
            errorsEncountered = true;
        }

        if (!errorsEncountered) {
            const newEditData = [...initialData];
            this.setState({
                editData: newEditData,
                hasChanges: false,
                cellChanges: {},
                revertData: [],
                saveSuccessDialogVisible: false,
                rowHeight: 2, // Reset row height to default
            });
        } else {
            // Handle error state, perhaps by notifying the user that undo could not be completed
        }
    };

    getVisibleHeaders = () => {
        const { visibleGroups } = this.state;

        if (visibleGroups.has('all')) {
            return this.columnHeaders.filter(header => header !== "Sort Help Column (leave me alone)");
        }

        let visibleHeaders = [];
        visibleGroups.forEach(group => {
            visibleHeaders = [...visibleHeaders, ...this.groupManager.getHeadersForGroup(group)];
        });

        return visibleHeaders.filter(header => header !== "Sort Help Column (leave me alone)");
    };

    toggleBoldForSelectedText = () => {
        let { enlargedCellValue } = this.state;
        const textarea = document.getElementById("editCellTextarea");
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        this.setState({
            enlargedCellValue: enlargedCellValue.substring(0, start) + "**" + enlargedCellValue.substring(start, end) + "**" + enlargedCellValue.substring(end),
        });
    };

    handleSave = async () => {
        this.setState({ isSaving: true, changesMade: [] });
        const { editData, initialData, cellChanges } = this.state;

        let updatesMade = [];
        let errorsEncountered = false;

        const changes = Object.keys(cellChanges).map(index => {
            const newValue = editData[index];
            const originalValue = initialData[index];
            const cellAddress = this.getCellAddress(parseInt(index));

            this.undoManager.addChange(cellAddress, originalValue);

            return {
                cellAddress,
                newValue,
                originalValue,
                description: `${this.columnHeaders[index]}: ${originalValue || '*null*'} changed to ${newValue || '*null*'}`
            };
        });

        this.setState({ revertData: this.undoManager.getRevertData() });

        try {
            const response = await this.graphService.updateCells('Accounts', changes);

            if (response.ok) {
                updatesMade = changes;
            } else {
                console.error(`Error updating cells: ${response.error}`);
                errorsEncountered = true;
            }
        } catch (error) {
            console.error("Error during batch update:", error);
            errorsEncountered = true;
        }

        if (errorsEncountered) {
            this.setState({
                isSaving: false,
                dialogVisible: true,
                changesMade: ["WARNING: Spreadsheet row changes were not saved, please try again - or manually store this information on the Accounts list."]
            });
        } else if (updatesMade.length > 0) {
            const changesFormattedForDisplay = updatesMade.map(update => update.description);

            this.setState({
                isSaving: false,
                cellChanges: {},
                saveSuccessDialogVisible: true,
                changesMade: changesFormattedForDisplay,
            });

            // Send report email after saving changes
            this.sendReportEmail();
        } else {
            this.setState({
                isSaving: false,
                dialogVisible: true,
                changesMade: ["No changes were made."]
            });
        }
    };

    sendReportEmail = async () => {
        const { changesMade } = this.state;
        const reportContent = changesMade.join('\n');
    
        try {
            const response = await fetch('https://automatedideas.net/send-report', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ reportContent, email: 'mike@automatedideas.com' })
            });
    
            if (!response.ok) {
                throw new Error('Failed to send report email');
            }
        } catch (error) {
            console.error('Error sending report email:', error);
        }
    };
    
    renderGroupToggles() {
        const groups = ['all', ...this.groupManager.getAllGroupHeaders()];
        return (
            <div className="groupTogglesContainer">
                {groups.map(group => (
                    <button
                        key={group}
                        onClick={() => this.toggleGroupVisibility(group)}
                        className={`groupHeaderButton ${this.state.visibleGroups.has(group) ? 'active' : ''}`}
                    >
                        {group}
                    </button>
                ))}
                <button onClick={this.handleSave} disabled={!this.state.hasChanges} className="saveChangesButton">
                    Save Changes
                </button>
            </div>
        );
    }

    renderSavingDialog() {
        return (
            <Dialog
                visible={this.state.saveSuccessDialogVisible}
                onHide={() => {
                    this.setState({ saveSuccessDialogVisible: false });
                    if (this.props.onDialogueClose) {
                        this.props.onDialogueClose();
                    }
                }}
                header="Changes Saved Successfully!"
                modal
                style={{ maxWidth: '100%', width: 'auto', margin: 'auto', textAlign: 'center' }}
                contentStyle={{ maxHeight: '80vh', overflow: 'auto', textAlign: 'center' }}
                footer={this.renderDialogFooter()}
            >
                {this.state.changesMade.length > 0 ? (
                    <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'center' }}>
                        {this.state.changesMade.map((change, index) => {
                            const [header, rest] = change.split(': ');
                            const [originalValue, newValue] = rest.split(' changed to ');
                            return (
                                <li key={index}>
                                    <strong>{header}:</strong>
                                    <span className="change-box">{originalValue || '*null*'}</span>
                                    changed to
                                    <span className="change-box">{newValue || '*null*'}</span>
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    <p>No changes were made.</p>
                )}
            </Dialog>
        );
    }

    renderDialogFooter = () => {
        const shouldShowUndo = this.state.revertData.length > 0;

        return (
            <div className="dialogFooter">
                {shouldShowUndo ? (
                    <button onClick={this.handleUndo} className="undoButton">
                        Undo
                    </button>
                ) : (
                    "No actions available"
                )}
            </div>
        );
    };

    render() {
        const { editData, isEnlargedViewOpen, enlargedCellValue, hasChanges, saveSuccessDialogVisible } = this.state;

        const propertyAddress = editData.length > 0 ? editData[0] : "Property Address Not Available";

        return (
            <div className="detailViewContainer">
                <div className="header">
                    {this.renderGroupToggles()}
                </div>

                <div className="propertyAddress">
                    {propertyAddress}
                </div>

                <div style={{ paddingTop: '50px' }}>
                    <div className="dataContainerStyle">
                        {editData.slice(0, this.columnHeaders.length).map((cell, index) => {
                            if (!this.getVisibleHeaders().includes(this.columnHeaders[index]) || !this.columnHeaders[index]) return null;

                            return (
                                <div key={index} className="cellStyle">
                                    <div className="cellHeaderStyle" onClick={() => this.openEnlargedView(index)}>
                                        {this.columnHeaders[index]}
                                    </div>
                                    <textarea
                                        id={`textArea-${index}`}
                                        className="uniformTextbox"
                                        value={cell || ''}
                                        onChange={(e) => this.handleCellChange(index, e.target.value)}
                                        style={{ height: 'auto' }} // Set initial height to auto
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>

                {isEnlargedViewOpen && (
                    <Dialog
                        header={this.renderDialogHeader()}
                        visible={isEnlargedViewOpen}
                        modal
                        onHide={this.closeEnlargedView}
                        style={{ width: '50vw', maxWidth: '100%', maxHeight: '100vh', overflow: 'auto' }}
                        contentStyle={{ width: '100%', overflow: 'auto' }}
                        footer={
                            <button
                                className="saveChangesButton"
                                onClick={this.saveCurrentCell}
                            >
                                Save
                            </button>
                        }
                    >
                        <textarea
                            id="editCellTextarea"
                            value={enlargedCellValue}
                            onChange={this.handleEnlargedCellChange}
                            style={{ width: '100%', height: 'calc(100vh - 150px)', overflow: 'auto', resize: 'none' }}
                        />
                    </Dialog>
                )}

                {saveSuccessDialogVisible && this.renderSavingDialog()}
            </div>
        );
    }
}

export default DetailView;
