// src/App.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import AuthPage from './components/AuthPage';
import SpreadsheetSearch from './components/SpreadsheetSearch';
import { MsalProvider, useIsAuthenticated } from '@azure/msal-react';
import { msalInstance } from './utils/auth';

// Creating a context for global actions that can be triggered from child components
const GlobalActionsContext = createContext({
  performGlobalAction: () => {}
});

function AuthenticatedRoute({ children }) {
  const isAuthenticated = useIsAuthenticated();
  let navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? children : null;
}

function AppWrapper() {
  const [globalState, setGlobalState] = useState({}); // Placeholder for any global state

  const performGlobalAction = () => {
    // Define global actions here, e.g., global refresh or logout
    console.log('Performing global action');
  };

  // Providing the performGlobalAction function to the entire app through context
  return (
    <MsalProvider instance={msalInstance}>
      <GlobalActionsContext.Provider value={{ performGlobalAction }}>
        <Router>
          <div className="appContainer">
            <Routes>
              <Route path="/" element={<AuthPage />} />
              <Route path="/search" element={
                <AuthenticatedRoute>
                  <SpreadsheetSearch />
                </AuthenticatedRoute>
              } />
            </Routes>
          </div>
        </Router>
      </GlobalActionsContext.Provider>
    </MsalProvider>
  );
}

export default AppWrapper;
export { GlobalActionsContext }; // Exporting context to use in child components
