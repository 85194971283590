import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn } from '../utils/auth';
import { useIsAuthenticated } from '@azure/msal-react';
import './AuthPage.css'; // Import the CSS file for styling
import logo from './logo.jpg';

function AuthPage() {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    // This effect takes care of redirection after authentication state changes.
    if (isAuthenticated) {
      navigate('/search');
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async () => {
    try {
      await signIn();
      // No further action needed here as useEffect will handle the redirection.
    } catch (error) {
      console.error('Authentication Error:', error);
    }
  };

  return (
    <div className="loginContainer">
      <img src={logo} alt="Logo" className="loginLogo"/>
      <button onClick={handleLogin} className="loginButton">Login</button>
    </div>
  );
}

export default AuthPage;
