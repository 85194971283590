export default class GroupManager {
    constructor(headers = []) {
      this.headers = headers;
      this.groups = {
        'Property Information': ['Property Address', 'Property City', 'Customer Name', 'Billing Contact', 'Account Manager', 'Project Status', 'Warranty Status', 'Onboard/Finish Date', 'General Contractor', 'Electrical Contractor', 'Contact Name', 'Contact Title', 'Contact Method', 'Contact Detail', 'Contact Phone'],
        'Lighting': ['Lighting Control Detail', 'LC Owner', 'Control System Detail', 'CS Owner'],
        'Shades': ['Shades'],
        'AV': ['Audio', 'Video', 'Theater'],
        'Networking': ['Router/Firewall', 'Network, Wired', 'Network, Wireless'],
        'Access Control': ['Access Controls', 'Surveillance', 'Communications']
      };
      this.updateOtherGroup();
      this.visibleGroups = new Set();
    }
  
    updateOtherGroup() {
      const predefinedGroups = new Set(Object.values(this.groups).flat());
      this.groups['Other'] = this.headers.filter(header => !predefinedGroups.has(header) && header !== "Sort Help Column (leave me alone)");
    }
  
    getHeadersForGroup(group) {
      return this.groups[group] || [];
    }
  
    getAllGroupHeaders() {
      return Object.keys(this.groups);
    }
  
    toggleGroupVisibility(groupName) {
      if (this.visibleGroups.has(groupName)) {
        this.visibleGroups.delete(groupName);
      } else {
        this.visibleGroups.add(groupName);
      }
  
      // Ensure 'Other' group is updated dynamically when toggling visibility
      if (groupName === 'Other') {
        this.updateOtherGroup();
      }
    }
  
    getVisibleHeaders() {
      if (this.visibleGroups.has('all')) {
        return this.headers.filter(header => header !== "Sort Help Column (leave me alone)");
      }
  
      let visibleHeaders = [];
      this.visibleGroups.forEach(group => {
        visibleHeaders = [...visibleHeaders, ...this.getHeadersForGroup(group)];
      });
  
      visibleHeaders = visibleHeaders.filter(header => header !== "Sort Help Column (leave me alone)");
      return visibleHeaders;
    }
  }
  



  