import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import DetailView from './DetailView';
import GraphService from '../utils/GraphService';
import logo from './logo.jpg'; // Ensure this is the correct path for the top-left logo
import loadingGif from '../components/gifs/loading.gif'; // Correct import path for the loading GIF
import { GlobalActionsContext } from '../App';

const SpreadsheetSearch = () => {
    const [rowData, setRowData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [headers, setHeaders] = useState([]);
    const [loading, setLoading] = useState(false);
    const searchInputRef = useRef(null);

    const { performGlobalAction } = useContext(GlobalActionsContext);

    const fetchData = async () => {
        setLoading(true);
        const graphService = new GraphService();
        const data = await graphService.getWorksheetData('Accounts');
        if (data && data.length > 0) {
            setHeaders(data[0]); // Set headers to the first row of the worksheet
            const filteredData = data.slice(1).map((row, index) => ({
                rowIndex: index,
                identifier: row[0],
                match: row.find(cell => cell.toString().toLowerCase().includes(globalFilter.toLowerCase())),
                fullRow: row
            })).filter(row => row.match);
            setRowData(filteredData);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [globalFilter]);

    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []);

    const onRowSelect = (e) => {
        setSelectedRow({ values: e.value.fullRow, rowIndex: e.value.rowIndex });
        setDisplayDialog(true);
    };

    const reloadData = () => {
        fetchData();
    };

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', margin: '20px' }}>
                <img src={logo} alt="Logo" style={{ position: 'absolute', top: '-10px', left: '-10px', maxWidth: '50px' }} />
                <input
                    type="text"
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search Accounts List"
                    style={{ width: 'calc(100% - 60px)', padding: '10px', fontSize: '16px', marginLeft: '45px' }}
                    ref={searchInputRef}
                />
            </div>
            <div style={{ overflowY: 'auto', maxHeight: "calc(100vh - 120px)" }}>
                <DataTable value={rowData} selectionMode="single" onSelectionChange={onRowSelect} globalFilter={globalFilter} style={{ width: '100%', backgroundColor: 'white' }}>
                    <Column field="identifier" header="Property Address" headerStyle={{ backgroundColor: 'white', color: '#000000', fontWeight: 'bold' }}></Column>
                    <Column field="match" header="Match" headerStyle={{ backgroundColor: 'white', color: '#000000', fontWeight: 'bold' }}></Column>
                </DataTable>
            </div>
            <Dialog visible={displayDialog} onHide={() => setDisplayDialog(false)} header="" style={{height: '100vw', maxHeight: '100%',width: '100vw', maxWidth: '100%' }}>
                {selectedRow && <DetailView rowData={selectedRow} columnHeaders={headers} onDialogueClose={reloadData} />}
            </Dialog>
            {loading && (
                <div className="loading" style={{ textAlign: 'center', marginTop: '20px' }}>
                    <img src={loadingGif} alt="Loading..." style={{ width: '50px' }} />
                </div>
            )}
        </>
    );
};

export default SpreadsheetSearch;
