import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalConfig } from '../authConfig';

// Initialize MSAL instance with your configuration
export const msalInstance = new PublicClientApplication(msalConfig);

// Define the scopes your application needs
export const loginRequest = {
  scopes: ["User.Read", "Files.ReadWrite.All", "Sites.ReadWrite.All"]
};

// Function to trigger the login flow
export const signIn = async () => {
  try {
    const response = await msalInstance.loginPopup(loginRequest);
    console.log('Login Success:', response.account.username);
    return response;
  } catch (error) {
    console.error('Login Failed:', error);
    throw error; // Re-throw to allow caller to handle
  }
};

// Function to acquire a token silently or with fallback
export const getAccessToken = async (scopes = loginRequest.scopes) => {
  try {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length === 0) throw new Error('No accounts detected.');

    try {
      // Attempt to acquire token silently
      const silentResult = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes
      });
      return silentResult.accessToken;
    } catch (silentError) {
      // Fallback to interactive method if silent token acquisition fails
      if (silentError instanceof InteractionRequiredAuthError) {
        try {
          const interactiveResult = await msalInstance.acquireTokenPopup({
            ...loginRequest,
            account: accounts[0],
            scopes
          });
          return interactiveResult.accessToken;
        } catch (interactiveError) {
          throw interactiveError;
        }
      } else {
        throw silentError;
      }
    }
  } catch (error) {
    console.error('Error acquiring access token:', error);
    throw error; // Re-throw to allow caller to handle
  }
};
