import { getAccessToken } from './auth';

class GraphService {
    constructor() {
        this.graphEndpoint = "https://graph.microsoft.com/v1.0";
        this.siteId = "61db3e41-a0b6-496d-9a64-605373a14b33";
        this.driveId = "b!QT7bYbagbUmaZGBTc6FLM7jTBiLh5DtLj4Z5ZNhPApEzX2Ylz74CSLdEibdCo4e8";
        this.itemId = "01D67C4CENTB6SHIOBOVBIA3LSU6OQG34B";
        this.sessionId = null;
    }

    async clearCell(worksheetName, cellAddress) {
        const accessToken = await getAccessToken(['Files.ReadWrite.All']);
        if (!accessToken) {
            console.error("No Access Token found for clearing the cell");
            return;
        }

        const url = `${this.graphEndpoint}/sites/${this.siteId}/drives/${this.driveId}/items/${this.itemId}/workbook/worksheets/${worksheetName}/range(address='${cellAddress}')`;

        try {
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ values: [[""]] }) // Assuming an empty string clears the cell
            });

            if (!response.ok) {
                const errorDetail = await response.text();
                throw new Error(`Failed to clear the cell: ${response.statusText} - Details: ${errorDetail}`);
            }
            console.log('Cell cleared successfully');
        } catch (error) {
            console.error('Error clearing the cell:', error);
        }
    }

    async createSession(persistChanges = true) {
        const accessToken = await getAccessToken(['Files.ReadWrite.All']);
        if (!accessToken) {
            console.error("No Access Token found for creating session");
            return;
        }

        const url = `${this.graphEndpoint}/me/drive/items/${this.itemId}/workbook/createSession`;
        const body = { persistChanges };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            if (!response.ok) {
                throw new Error(`Failed to create session: ${response.statusText}`);
            }

            const data = await response.json();
            this.sessionId = data.id;
            console.log('Session created successfully', this.sessionId);
        } catch (error) {
            console.error('Error creating session:', error);
        }
    }

    async closeSession() {
        if (!this.sessionId) {
            console.log("No active session to close");
            return;
        }

        const accessToken = await getAccessToken(['Files.ReadWrite.All']);
        if (!accessToken) {
            console.error("No Access Token found for closing session");
            return;
        }

        const url = `${this.graphEndpoint}/me/drive/items/${this.itemId}/workbook/closeSession`;

        try {
            await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ sessionId: this.sessionId })
            });

            this.sessionId = null;
            console.log('Session closed successfully');
        } catch (error) {
            console.error('Error closing session:', error);
        }
    }

    async getWorksheetData(worksheetName = 'Accounts') {
        const accessToken = await getAccessToken(['Files.ReadWrite.All']);
        if (!accessToken) {
            console.error("No Access Token found");
            return [];
        }

        const url = `${this.graphEndpoint}/sites/${this.siteId}/drives/${this.driveId}/items/${this.itemId}/workbook/worksheets/${worksheetName}/usedRange(valuesOnly=true)`;

        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                method: 'GET'
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch worksheet data: ${response.statusText}`);
            }
            const data = await response.json();
            return data.values;
        } catch (error) {
            console.error('Error fetching worksheet data:', error);
            return [];
        }
    }

    async updateRange(worksheetName, rangeAddress, values, forceUpdate = false) {
        const accessToken = await getAccessToken(['Files.ReadWrite.All']);
        if (!accessToken) {
            console.error("No Access Token found for updating range");
            return;
        }

        const url = `${this.graphEndpoint}/sites/${this.siteId}/drives/${this.driveId}/items/${this.itemId}/workbook/worksheets/${worksheetName}/range(address='${rangeAddress}')`;

        try {
            let response;
            if (forceUpdate) {
                response = await this.performPatchRequest(url, values, accessToken);
            } else {
                response = await this.performPatchRequest(url, values, accessToken);
                if (!response.ok) {
                    console.log('Retry with appended space character...');
                    const modifiedValues = values.map(row => row.map(value => `${value} `)); // Append space to each value
                    response = await this.performPatchRequest(url, modifiedValues, accessToken);
                    if (!response.ok) throw new Error('Retry failed');
                }
            }
            console.log('Range updated successfully');
        } catch (error) {
            console.error('Error updating the range:', error);
        }
    }

    async updateCell(worksheetName, cellAddress, newValue, forceUpdate = false) {
        const accessToken = await getAccessToken(['Files.ReadWrite.All']);
        if (!accessToken) {
            console.error("No Access Token found for updating the cell");
            return { ok: false, error: "No Access Token found" };
        }

        const url = `${this.graphEndpoint}/sites/${this.siteId}/drives/${this.driveId}/items/${this.itemId}/workbook/worksheets/${worksheetName}/range(address='${cellAddress}')`;

        // Use the updated performPatchRequest method that handles response interpretation
        const response = forceUpdate
            ? await this.performPatchRequest(url, [[newValue]], accessToken)
            : await this.performPatchRequest(url, [[newValue]], accessToken);

        // Retry logic remains unchanged but now correctly interprets the structured response
        if (!response.ok && !forceUpdate) {
            console.log('Initial attempt failed. Trying with appended space character...');
            return await this.performPatchRequest(url, [[`${newValue} `]], accessToken);
        }
        return response;
    }

    // Utility method to perform PATCH requests and interpret the response
    async performPatchRequest(url, values, accessToken) {
        try {
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ values: values })
            });
            if (!response.ok) {
                // If the server response is not OK, construct an error message
                const errorDetail = await response.text();
                return { ok: false, error: `Failed to update: ${response.statusText} - Details: ${errorDetail}` };
            }
            // If the fetch request was successful, return ok: true
            return { ok: true };
        } catch (error) {
            // Catch any errors related to the fetch operation itself
            console.error('Fetch error:', error);
            return { ok: false, error: error.toString() };
        }
    }

    async updateCells(sheetName, changes) {
        const accessToken = await getAccessToken(['Files.ReadWrite.All']);
        if (!accessToken) {
            console.error("No Access Token found for updating cells");
            return { ok: false, error: "No Access Token found" };
        }

        const batchRequest = changes.map(change => {
            return {
                id: change.cellAddress,
                method: 'PATCH',
                url: `/sites/${this.siteId}/drives/${this.driveId}/items/${this.itemId}/workbook/worksheets/${sheetName}/range(address='${change.cellAddress}')`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: {
                    values: [[change.newValue]]
                }
            };
        });

        const url = `${this.graphEndpoint}/$batch`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ requests: batchRequest })
            });

            if (!response.ok) {
                const errorDetail = await response.text();
                throw new Error(`Failed to update cells: ${response.statusText} - Details: ${errorDetail}`);
            }
            return { ok: true };
        } catch (error) {
            console.error('Error updating cells:', error);
            return { ok: false, error: error.toString() };
        }
    }
}

export default GraphService;
