// src/authConfig.js
export const msalConfig = {
    auth: {
        clientId: "71a48c49-ef00-4cc9-92c1-b2fe5500ffaf", // Your Client ID
        authority: "https://login.microsoftonline.com/7eecf2f4-26f3-4224-b4d3-8e894483ea2c", // Your Tenant ID
        redirectUri: "https://automatedideas.net/"
    }
};

// export const loginRequest = {
//     scopes: ['User.Read', 'Files.Read.All', 'Sites.Read.All'] // Include needed SharePoint permissions
// };

//console.log('loginRequest in authConfig:', loginRequest); // Log here
